const axios = require("axios");

export async function createCheckoutSession({ price, locationId, locationMetaId }) {
  if (!price) {
    throw new Error(
      "Please provide a price parameter to createCheckoutSession"
    );
  }
  if (!locationId) {
    throw new Error(
      "Please provide an locationId parameter to createCheckoutSession"
    );
  }
  try {
    const createResults = await axios({
      method: "POST",
      url: `${process.env.REACT_APP_CRM_API_URL}/createCheckoutSession`,
      data: {
        price,
        locationId,
        locationMetaId,
      },
    });
    if (createResults && createResults.data && createResults.data.id) {
      return { success: true, sessionId: createResults.data.id };
    } else {
      throw new Error("createResults are malformed.");
    }
  } catch (error) {
    return { success: false, error };
  }
}
